<template>    
  <div>    
    <Cash @getFinancesCash="sendData($event, 'cash')" 
          :points_sales="points_sales"
          :reset="reset"
          :amount_pending="amount.total"
          type="ADD"
          class="mt-2"/>

    <Cheques @getFinancesCheques="sendData($event, 'cheques')" 
             :points_sales="points_sales"
             :reset="reset"
             type="ADD"
             class="mt-2"/>

    <hr>
    <b-input-group size="sm" :prepend="'TOTAL RECIBIDO'">
      <b-form-input v-model="finances.totalFormat" 
                    type="text"
                    :readonly="true"                    
                    class="input-add-total">
      </b-form-input>   
    </b-input-group>

    <b-input-group size="sm" :prepend="'TOTAL A IMPUTAR'" class="mt-2" v-if="amount.impute">
      <b-form-input v-model="amount.imputeFormat" 
                    type="text"
                    :readonly="true"                    
                    class="input-add-total">
      </b-form-input>   
    </b-input-group>   
    
    <hr v-if="amount.impute">

    <b-input-group size="sm" :prepend="amount.totalLegend" class="mt-2" v-if="amount.impute">
      <b-form-input v-model="amount.totalFormat" 
                    type="text"
                    :readonly="true"                    
                    class="input-add-total">
      </b-form-input>   
    </b-input-group>  

  </div>
</template>
<script>  
  import serviceAPI from './services'
  import ErrorToken from '@/handler/errorToken'
  import Helper from '@/handler/helper'
  import Cash from './types/cash'  
  import Cheques from './types/cheques'    
  import PositiveBalance from './types/positiveBalance'

  export default {
    props:{
      customers_id: {
        type: Number,
        default: 0,
      },
      points_sales: {
        type: Object,
        default: null,
      },      
      reset: {
        type: Boolean,
        default: false
      },
      amount_impute: {
        type: Number,
        default: 0,
      }, 
      default: {
        type: Object,
        default: null,        
      }            
    },    
    components: {
      Cash,      
      Cheques,
      PositiveBalance,
    },    
    data() {
      return {        
        finances: {
          total: 0,
          totalFormat: '',
          cash: {
            total: 0,            
            detail: null,    
          },
          cheques: {
            total: 0,            
            detail: null,    
          },                    
        },
        amount: {
          total: 0,
          totalFormat: '',
          totalLegend: '',
          impute: 0,
          imputeFormat: '',
        }
      }
    },
    watch: {        
      'reset': function (newQuestion, oldQuestion) {    
        if(newQuestion == true) {          
          this.finances = {
            total: 0,
            totalFormat: '',
            cash: {
              total: 0,            
              detail: null,    
            },
            cheques: {
              total: 0,            
              detail: null,    
            },          
          }
          this.$emit('getFinances', this.finances) 
        }
      },
      'amount_impute': function (newQuestion, oldQuestion) {
        this.calcImpute()
      },
      'default': function (newQuestion, oldQuestion) {
        if(newQuestion) {
          this.finances = newQuestion
          this.calcImpute()
        }      
      },
    },    
    computed: {
      getCurrencySymbol() {
        return '$'
      },
      getCurrencyCode() {
        return 'ARS'
      }      
    },
    mounted() {
      this.calcImpute()
    },        
    methods: {
      sendData(data, type) {                    
        if(type == 'cash') {
          this.finances.cash = data                  
        }

        if(type == 'cheques') {
          this.finances.cheques = data
        }        

        this.finances.total = 0
        this.finances.total = parseFloat(this.finances.total) + parseFloat(this.finances.cash.total)
        this.finances.total = parseFloat(this.finances.total) + parseFloat(this.finances.cheques.total)        
        
        this.finances.totalFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencyCode}).format(this.finances.total)

        this.calcImpute()

        this.$emit('getFinances', this.finances)         
      },
      calcImpute() {
        this.finances.totalFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencyCode}).format(this.finances.total)

        this.amount.impute = parseFloat(this.amount_impute)
        this.amount.imputeFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencyCode}).format(this.amount.impute)

        this.amount.total = parseFloat(this.finances.total) - parseFloat(this.amount.impute)
        this.amount.totalFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencyCode}).format(this.amount.total)
        if(this.amount.total == 0) {
          this.amount.totalLegend = 'SALDADO'
        } else {
          if(this.amount.total > 0) {
            this.amount.totalLegend = 'SALDO A CUENTA'
          } else {
            this.amount.totalLegend = 'POR SALDAR'
          }
        }
      }      
    }            
  }
</script>
<style scoped>
  .input-add-total {
    font-weight: 800;
    font-size: 15px;
    text-align: right;
  } 
  .input-group-text {
    width: 125px;
  }  
</style>